<template>
  <va-card title="Update Setting">
    <div>
      <form>
        <va-input
          v-model="setting.tax_name"
          type="text"
          label="Tax Name"
          placeholder="Enter Tax Name"
          :error="!!taxNameErrors.length"
          :error-messages="taxNameErrors"
          :disabled="true"
        />   
        <va-input
          v-model="setting.percentage"
          type="number"
          label="Tax Percentage"
          placeholder="Enter Tax Percentage"
          :error="!!taxPercentageErrors.length"
          :error-messages="taxPercentageErrors"
        />
        <va-select
          label="Currency *"
          v-model="setting.currency"
          textBy="id"
          :options="currency_obj"
          :error="!!currencyErrors.length"
          :error-messages="currencyErrors"
          noClear
        />
        <va-input
          v-model="setting.renewal_notice_days"
          type="number"
          label="Renewal Notice Days"
          placeholder="Enter Renewal Notice Days"
          :error="!!renewalNoticeDaysErrors.length"
          :error-messages="renewalNoticeDaysErrors"
        />

        <div class="d-flex justify--center mt-3">
          <va-button type="submit" class="my-0" @click.prevent="updateSetting()">Update</va-button>
        </div>
      </form>
    </div>
  </va-card>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en.json'
Vue.use(vueResource)

export default {
  name: 'genres',
  created () {
    this.getAllSetting();
    // const loader = Vue.$loading.show({ width: 40, height: 40 })
    // this.$http.get(config.menu.host + '/setting').then(response => {
    //   loader.hide()
    //   if (response.body.length > 0) {
    //     this.setting = response.body[0]
    //   }
    // }, error => {
    //   loader.hide()
    //   if (error && error.body) {
    //     Vue.notify({ text: error.body, type: 'error' })
    //   }
    // })
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      currency_obj: [{ id: 'INR' }],
      taxPercentageErrors: [],
      setting: {
        currency: '',
        tax_name: 'GST',
        renewal_notice_days: '',
        percentage: '',
      },
      currencyErrors: [],
      taxNameErrors: [],
      renewalNoticeDaysErrors: [],
    }
  },
  methods: {
    getAllSetting () {
      this.$http.get(config.menu.host + '/setting').then(response => {
        if (response.body.length > 0) {
          this.setting = response.body[0]
        }
      })
    },
    updateSetting () {
      var payload = {
        tax_name: this.setting.tax_name,
        percentage: this.setting.percentage,
        renewal_notice_days: this.setting.renewal_notice_days,
      }
      if (!this.setting.id) {
        payload.currency = this.setting.currency.id
        this.$http.post(config.menu.host + '/setting', payload).then(responseData => {
          if (responseData && responseData.body) {
            Vue.notify({ text: responseData.body, type: 'success' })
          }
          this.list()
        }, errResponse => {
          if (errResponse && errResponse.body) {
            Vue.notify({ text: errResponse.body, type: 'error' })
          }
        })
      } else {
        payload.currency = this.setting.currency
        this.$http.put(config.menu.host + '/setting/' + this.setting.settings_id, payload).then(responseData => {
          if (responseData && responseData.body) {
            Vue.notify({ text: responseData.body, type: 'success' })
          }
          this.list()
        }, errResponse => {
          if (errResponse && errResponse.body) {
            Vue.notify({ text: errResponse.body, type: 'error' })
          }
        })
      }
    },
    list () {
      this.isshowForm = false
      this.getAllSetting()
    },
  },
}
</script>
